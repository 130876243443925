import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./styles.css";
import logo from "../../assets/img/tupiIcon.png";
import logoEffix from "../../assets/img/logo-effix.svg";
import successIcon from "../../assets/img/success.png";
import loadingIcon from "../../assets/img/loading.png";
import chargingIcon from "../../assets/img/charging.png";
import errorIcon from "../../assets/img/error.png";
import socket from "socket.io-client";
import queryString from "query-string";
import { QrCode } from "../../components";
import { FreireContext } from "../../utils/freireContext";
import * as texts from "../../utils//locales";
import isAvailable from "../../utils/status";

const successColor = "#70ce28";
const loadingColor = "#f37b0f";
const chargingColor = "#ee5654";
const errorColor = "#ef4e17";

const EXAMPLE_STATION_ID = "CPTUPI001";

class Info extends Component {
  static contextType = FreireContext;

  state = {
    stationID: "",
    titleMessage: "",
    titleColor: successColor,
    message: "",
    loading: false,
    charging: false,
    reserved: false,
    error: false,
    lastError: Date.now(),
    hasLogoEffix: false,
    qrcodeCompany: "tupinamba",
  };

  onConnect = (data) => {
    const { freire } = this.context;
    this.setState(
      {
        titleMessage: "",
        message: "",
        error: false,
        loading: false,
      },
      () => {
        console.log(freire(texts.STATION_AVAILABLE));
        // eslint-disable-next-line no-restricted-globals
        parent.postMessage("ready", "*");
        console.log("postMessage ->", "ready");
      }
    );
  };

  onConfig = async (station) => {
    this.changeLanguage(station.language);
    const available = isAvailable(station.connectedPlugs);
    available ? this.onAvailable() : this.onUnavailable();
  };

  onConnectError = (data) => {
    const { freire } = this.context;

    console.log("connect_error", data);
    this.setState({
      loading: false,
      error: true,
      titleColor: errorColor,
      titleMessage: "Ops...",
      message: freire(texts.CONNECTED_ERROR),
    });
  };

  onDisconnect = (data) => {
    const { freire } = this.context;

    console.log("disconnect", data);
    this.setState({
      loading: false,
      error: true,
      titleColor: errorColor,
      titleMessage: "Ops...",
      message: freire(texts.CONNECTED_ERROR),
    });
  };

  onAvailable = (data) => {
    const { freire } = this.context;

    console.log(`${this.state.stationID} | onAvailable ->`, data);

    this.setState({
      loading: false,
      charging: false,
      reserved: false,
      error: false,
      titleColor: successColor,
      titleMessage: freire(texts.STATION_AVAILABLE),
      message: freire(texts.STATION_AVAILABLE_MESSAGE),
    });
  };

  onUnavailable = (data) => {
    const { freire } = this.context;

    console.log(`${this.state.stationID} | onUnavailable ->`, data);

    this.setState({
      loading: false,
      charging: false,
      reserved: false,
      error: true,
      titleColor: errorColor,
      titleMessage: "Ops...",
      message: freire(texts.STATION_UNAVAILABLE),
    });
  };

  onStart = (data) => {
    const { freire } = this.context;
    this.setState({
      loading: false,
      charging: true,
      reserved: false,
      error: false,
      titleColor: chargingColor,
      titleMessage: freire(texts.CHARGING),
      message: freire(texts.ON_CHARGING),
    });
  };

  onReserved = (data) => {
    const { freire } = this.context;
    this.setState({
      loading: false,
      charging: false,
      reserved: true,
      error: false,
      titleColor: loadingColor,
      titleMessage: freire(texts.STATION_RESERVED),
      message: freire(texts.ON_RESERVED),
    });
  };

  onLoading = (data) => {
    const { freire } = this.context;

    this.setState({
      loading: true,
      charging: false,
      reserved: false,
      error: false,
      titleColor: loadingColor,
      titleMessage: freire(texts.LOADING),
      message: freire(texts.ON_CHARGING),
    });
  };

  onError = (data) => {
    const { freire } = this.context;
    this.setState({
      loading: false,
      charging: false,
      reserved: false,
      error: true,
      titleMessage: "Ops...",
      titleColor: errorColor,
      message: freire(texts[data]),
      lastError: Date.now(),
    });
  };

  onReload = () => {
    console.log("postMessage ->", "reload");
    // TODO: remove REACT_APP_SHOW_ENV_NAME
    if (
      process.env.REACT_APP_IS_DEVELOPMENT ||
      process.env.REACT_APP_SHOW_ENV_NAME
    ) {
      window.location.reload();
    } else {
      // eslint-disable-next-line no-restricted-globals
      parent.postMessage("reload", "*");
    }
  };

  subscribeToEvents = () => {
    const { stationID } = this.state;

    const io = socket(process.env.REACT_APP_API_URL, {
      query: `stationID=${stationID}`,
    });

    io.on("connect", this.onConnect);
    io.on("reconnect", this.onConnect);
    io.on("connect_error", this.onConnectError);
    io.on("disconnect", this.onDisconnect);

    io.on(`${stationID}:config`, this.onConfig);

    io.on(`${stationID}:available`, this.onAvailable);
    io.on(`${stationID}:unavailable`, this.onUnavailable);
    io.on(`${stationID}:start`, this.onStart);
    io.on(`${stationID}:reserved`, this.onReserved);
    io.on(`${stationID}:loading`, this.onLoading);
    io.on(`${stationID}:error`, this.onError);

    // Enviado do back para todas as estações
    io.on("reload", this.onReload);
    // Enviado do back para essa estação em específico
    io.on(`${stationID}:reload`, this.onReload);

    // io.on("message", (data) => {
    //   console.log("data ->", data);
    // });
  };

  changeLanguage = (languageCode) => {
    const { freire } = this.context;
    freire.setUserLanguage(languageCode);
  };

  componentDidMount() {
    const { freire } = this.context;

    // Pra ficar dinâmico e pegar da URL o stationID:
    let params = queryString.parse(this.props.location.search);

    if (params && params.stationID === undefined) {
      // Pra ficar fixo:
      params = { stationID: "CPTUPI003" };
    }
    try {
      if (Object.keys(params).length > 0) {
        if (params.stationID) {
          this.setState({ stationID: params.stationID }, () => {
            this.subscribeToEvents();
          });
        } else {
          throw Error(freire(texts.ERROR_GET_STATION_ID(EXAMPLE_STATION_ID)));
        }

        this.setState({
          hasLogoEffix: params.hasLogoEffix || false,
          qrcodeCompany: params.qrcodeCompany || "tupinamba",
        });
      } else {
        throw Error(freire(texts.NO_PARAMS_INFORMED(EXAMPLE_STATION_ID)));
      }
    } catch (e) {
      this.setState({
        loading: false,
        error: true,
        titleMessage: "Ops...",
        titleColor: errorColor,
        message: e.message,
        lastError: Date.now(),
      });
    }
  }

  render() {
    const {
      stationID,
      titleMessage,
      titleColor,
      message,
      error,
      loading,
      charging,
      reserved,
      hasLogoEffix,
    } = this.state;
    const { freire } = this.context;

    function setIcon() {
      if (charging) {
        return chargingIcon;
      } else if (loading || reserved) {
        return loadingIcon;
      } else if (error) {
        return errorIcon;
      }

      return successIcon;
    }

    return (
      <>
        <div className="bgTransparent"></div>

        <div className="container">
          <div
            className="indicator"
            style={error ? { backgroundColor: "#ef4e17" } : {}}
          />

          {titleMessage && (
            <>
              <div className="row">
                <img className="icon" src={setIcon()} alt="icon" />
                <span className="titleMessage" style={{ color: titleColor }}>
                  {titleMessage}
                </span>
              </div>

              <div className="row rowMessage">
                <span className="message">{message}</span>
              </div>
            </>
          )}
          <div className="footer">
            {process.env.REACT_APP_SHOW_ENV_NAME === "true" && (
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "4vw",
                }}
              >
                {process.env.REACT_APP_ENV_NAME.toUpperCase()}
              </span>
            )}
            {hasLogoEffix && (
              <img
                className="logoEffix"
                src={logoEffix}
                alt={freire(texts.LOGO_COMPANY("Effix"))}
              />
            )}
            <img
              className="logoTupi"
              src={logo}
              alt={freire(texts.LOGO_TUPI)}
            />
            {!charging && !loading && stationID && (
              <QrCode stationID={stationID} />
            )}
          </div>

          {/* <div className="row">
            <h2 className="titleMessage" style={{ color: titleColor }}>
              {titleMessage}
            </h2>

            <img className="logo" src={logo} alt="logo" />
          </div>

          <div className="row">
            {loading ? (
              <div className="lds-dual-ring"></div> // loading
            ) : !charging && stationID ? (
              <QrCode stationID={stationID} />
            ) : null}

            <span className="message">{message}</span>
          </div> */}
        </div>
      </>
    );
  }
}
export default withRouter(Info);
