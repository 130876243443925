export const STATION_AVAILABLE = {
  pt: "Estação Livre",
  es: "Estación Libre",
  en: "Available station",
};

export const CHARGING = {
  pt: "Carregando",
  es: "Cargando",
  en: "Charging",
};

export const STATION_AVAILABLE_MESSAGE = {
  pt: "Conecte o plug ao seu veículo e leia o QR Code com o App Tupinambá para começar a carregar.",
  es: "Conecte el enchufe a su vehículo y lea el QR Code con el App Tupinambá para comenzar a cargar.",
  en: "Connect the plug to your vehicle and read the QR Code with the Tupinambá App to start charging.",
};

export const CONNECTED_ERROR = {
  pt: "Não foi possível se conectar ao servidor",
  es: "No fue posible conectarse con el servidor",
  en: "It could not connect to server",
};

export const DISCONNECTED = {
  pt: "Desconectado",
  es: "Desconectado",
  en: "Disconnected",
};

export const ON_CHARGING = {
  pt: "Consulte o App Tupinambá para mais informações.",
  es: "Consulte la App Tupinambá para obtener más informaciones.",
  en: "See the Tupinambá App for more information.",
};

export const LOADING = {
  pt: "Aguarde...",
  es: "Espere...",
  en: "wait...",
};
export const ON_RESERVED = {
  pt: "Deseja reservar da próxima vez? Baixe o app da Tupi.",
  es: "¿Desea reservar la próxima vez? Descargue la app de Tupi.",
  en: "Want to book next time? Download the Tupi app.",
};

export const ERROR_GET_STATION_ID = (EXAMPLE_STATION_ID) => ({
  pt: `stationID não informado na URL. É necessário informar o stationID na URL. Ex.: ${window.location.origin}?stationID=${EXAMPLE_STATION_ID}`,
  es: `stationID no informado en la URL. Es necesario informar el stationID en la URL. Ej.: ${window.location.origin}?stationID=${EXAMPLE_STATION_ID}`,
  en: `stationID not specified in the URL. You must specify the stationID in the URL. Ex: ${window.location.origin}?stationID=${EXAMPLE_STATION_ID}`,
});

export const NO_PARAMS_INFORMED = (EXAMPLE_STATION_ID) => ({
  pt: `Nenhum parâmetro informado. É necessário informar o stationID na URL. Ex.: ${window.location.origin}?stationID=${EXAMPLE_STATION_ID}`,
  es: `Ningún parámetro informado. Es necesario informar el stationID en la URL. Ej.: ${window.location.origin}?stationID=${EXAMPLE_STATION_ID}`,
  en: `No parameter informed. You must specify the stationID in the URL. Ex: ${window.location.origin}?stationID=${EXAMPLE_STATION_ID}`,
});

export const LOGO_TUPI = {
  pt: "Logo Tupinambá",
  es: "Logotipo Tupinambá",
  en: "Tupinambá logo",
};

export const LOGO_COMPANY = (COMPANY) => ({
  pt: `Logo ${COMPANY}`,
  es: `Logotipo ${COMPANY}`,
  en: `${COMPANY} Logo`,
});

export const ERROR_START = {
  pt: "Não foi possível iniciar a carga:",
  es: "No fue posible iniciar la carga:",
  en: "Unable to start charge:",
};

export const STATION_PREPARING = {
  pt: "Verifique se o plug está conectado corretamente ao veículo e tente novamente.",
  es: "Verifique si el enchufe está conectado correctamente al vehículo e intente nuevamente.",
  en: "Make sure the plug is connected correctly to the vehicle and try again.",
};

export const STATION_SUSPENDED_EVSE = {
  pt: "Veículo está conectado à estação, mas a estação não está oferecendo energia ao veículo",
  es: "El vehículo está conectado a la estación, pero la estación no ofrece energía al vehículo",
  en: "Vehicle is connected to the station, but the station is not providing power to the vehicle",
};

export const STATION_SUSPENDED_EV = {
  pt: "Veículo está conectado à estação, mas o veículo não está consumindo energia",
  es: "Vehículo está conectado a la estación, pero el vehículo no está consumiendo energía",
  en: "Vehicle is connected to the station, but the vehicle is not consuming power",
};

export const STATION_FINISHING = {
  pt: "A transação atual foi interrompida, mas o cabo não foi removido",
  es: "La transacción actual fue interrumpida, pero el cable no fue retirado",
  en: "The current transaction has been stopped but the cable has not been removed",
};

export const STATION_RESERVED = {
  pt: "Estação reservada",
  es: "Estación reservada",
  en: "Reserved station",
};

export const STATION_UNAVAILABLE = {
  pt: "Estação indisponível",
  es: "Estación no disponible",
  en: "Unavailable station",
};

export const STATION_FAULTED = {
  pt: "A estação relatou um erro e está indisponível",
  es: "La estación informó un error y no está disponible",
  en: "The station reported an error and is unavailable",
};

export const ERROR_ADDRESS_APP = {
  pt: "Atualize o App e/ou insira seu endereço para iniciar uma nova carga",
  es: "Actualice la App y/o introduzca su dirección para iniciar una nueva carga",
  en: "Update the App and/or enter your address to start a new charge",
};

export const error_check_app = {
  pt: `${ERROR_START.pt} ${ON_CHARGING.pt}`,
  es: `${ERROR_START.es} ${ON_CHARGING.es}`,
  en: `${ERROR_START.en} ${ON_CHARGING.en}`,
};

export const try_again = {
  pt: "Tente novamente, por favor.",
  es: "Por favor, Inténtelo nuevamente.",
  en: "Please try again.",
};

export const error_att = {
  pt: "Atualize o App e/ou insira seu endereço para iniciar uma nova carga",
  es: "Actualice la App y/o introduzca su dirección para iniciar una nueva carga",
  en: "Update the App and/or enter your address to start a new charge",
};

export const error_Payment = {
  pt: "Error no pagamento, verifique seus dados de pagamento!",
  es: "¡Error de pago, por favor, compruebe sus datos de pago!",
  en: "Payment error, please complete your payment details!",
};

export const error_Available = {
  pt: "Não foi possível iniciar a carga: Verifique se o plug está conectado corretamente ao veículo e tente novamente.",
  es: "No fue posible iniciar la carga: Verifique si el enchufe está conectado correctamente al vehículo e intente nuevamente.",
  en: "Unable to start charge: Make sure the plug is connected correctly to the vehicle and try again.",
};

export const error_Preparing = {
  pt: "Não foi possível iniciar a carga: Verifique se o plug está conectado corretamente ao veículo e tente novamente.",
  es: "No fue posible iniciar la carga: Verifique si el enchufe está conectado correctamente al vehículo e intente nuevamente.",
  en: "Unable to start charge: Make sure the plug is connected correctly to the vehicle and try again.",
};

export const error_SuspendedEVSE = {
  pt: "Não foi possível iniciar a carga: Veículo está conectado à estação, mas a estação não está oferecendo energia ao veículo",
  es: "No fue posible iniciar la carga: El vehículo está conectado a la estación, pero la estación no ofrece energía al vehículo",
  en: "Unable to start charge: Vehicle is connected to the station, but the station is not providing power to the vehicle",
};

export const error_SuspendedEV = {
  pt: "Não foi possível iniciar a carga: Veículo está conectado à estação, mas o veículo não está consumindo energia",
  es: "No fue posible iniciar la carga: Vehículo está conectado a la estación, pero el vehículo no está consumiendo energía",
  en: "Unable to start charge: Vehicle is connected to the station, but the vehicle is not consuming power",
};

export const error_Finishing = {
  pt: "Não foi possível iniciar a carga: A transação atual foi interrompida, mas o cabo não foi removido",
  es: "No fue posible iniciar la carga: La transacción actual fue interrumpida, pero el cable no fue retirado",
  en: "Unable to start charge: The current transaction has been stopped but the cable has not been removed",
};

export const error_Reserved = {
  pt: "Não foi possível iniciar a carga: Estação reservada",
  es: "No fue posible iniciar la carga: Estación reservada",
  en: "Unable to start charge: Reserved station",
};

export const error_Unavailable = {
  pt: "Não foi possível iniciar a carga: Estação indisponível",
  es: "No fue posible iniciar la carga: Estación no disponible",
  en: "Unable to start charge: Unavailable station",
};

export const error_Faulted = {
  pt: "Não foi possível iniciar a carga: A estação relatou um erro e está indisponível",
  es: "No fue posible iniciar la carga: La estación informó un error y no está disponible",
  en: "Unable to start charge: The station reported an error and is unavailable",
};
