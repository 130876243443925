import React, { useMemo, useRef } from "react";

import Routes from "./routes";
import { useForceUpdate } from "./hooks/force-update";
import { currentLanguage } from "./utils/current-language";
import Freire from "./utils/freire";
import { FreireContext } from "./utils/freireContext";

const DEFAULT_LANGUAGE = "pt";
const LOCAL_STORAGE_LANGUAGE_KEY = "language";

const navigatorLanguage = navigator?.language ?? navigator.userLanguage;

const App = () => {
  const forceUpdate = useForceUpdate();

  const firstLanguageChange = useRef(true);

  const onLanguageChange = (freire) => {
    currentLanguage.ref = freire.userLanguage;
    forceUpdate();
    if (firstLanguageChange.current) {
      firstLanguageChange.current = false;
    } else {
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, freire.userLanguage);
    }
  };

  const storedLanguage = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY);

  const freire = useMemo(
    () => {
      const userLanguage = storedLanguage ?? navigatorLanguage;
      return Freire(userLanguage, DEFAULT_LANGUAGE, onLanguageChange);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <FreireContext.Provider value={{ freire }}>
      <Routes />
    </FreireContext.Provider>
  );
};

export default App;
