import React from 'react';
import { BrowserRouter, Route, Switch, } from 'react-router-dom';
import Info from './pages/Info';
// import PrivateRoute from './PrivateRoute';

const Routes = () => (
  <BrowserRouter basename="/admin">
    <Switch>
      <Route path="/" exact component={Info}/>
      {/* <PrivateRoute path="/dashboard" component={Dashboard} /> */}
    </Switch>
  </BrowserRouter>
);

export default Routes;
