/**
 * @name Freire
 * @author lnasc256 <iam@lucasnascimen.to>
 * @description FreireJS
 */

export const DEFAULT_LANGUAGE = "pt-BR";

export const DEFAULT_TEXT = { en: "Blank text" };

export const TEXT_NOT_FOUND = {
  en: "Text not found",
  pt: "texto não encontrado",
};

const hasOwnProperty = (object, key) =>
  Object.prototype.hasOwnProperty.call(object, key);

/**
 * @name Freire
 * @param {string} [userLanguage=en] - User language
 * @param {string} [defaultLanguage=en] - Default language to fallback
 * @param {function} [middleware] - Language middleware
 */
const Freire = (
  userLanguage = DEFAULT_LANGUAGE,
  defaultLanguage = DEFAULT_LANGUAGE,
  middleware
) => {
  /**
   * @name freire
   * @description Translates the text
   * @param {} [text=DEFAULT_TEXT] - Text to translate
   * @returns {string} Text translated
   */
  const freire = (text = DEFAULT_TEXT) => {
    const { userLanguage, defaultLanguage } = freire;

    if (hasOwnProperty(text, userLanguage)) {
      return text[userLanguage];
    }

    const language = userLanguage.match(/^(\w+)[-_]?/);
    if (language) {
      const extractedLanguage = language[1];
      if (hasOwnProperty(text, extractedLanguage)) {
        return text[extractedLanguage];
      }

      const [fromSame] = Object.keys(text).filter((key) =>
        key.startsWith(language[0])
      );

      if (fromSame) {
        return text[fromSame];
      }
    }

    if (hasOwnProperty(text, defaultLanguage)) {
      return text[defaultLanguage];
    }

    return Object.values(text)[0] ?? freire(TEXT_NOT_FOUND);
  };

  freire.setUserLanguage = (userLanguage) => {
    freire.userLanguage = userLanguage;
    middleware?.(freire);
  };

  freire.reset = () => {
    freire.defaultLanguage = defaultLanguage;
    freire.setUserLanguage(userLanguage);
  };

  freire.reset();

  return freire;
};

export default Freire;
