import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import logoQrCode from "../../assets/img/qrLogo.png";
// import logoQrCodeTupinamba from "../../assets/img/logo-qrcode-tupinamba.png";
// import logoQrCodeNeocharge from "../../assets/img/logo-qrcode-neocharge.png";
// import logoQrCodeMobilize from "../../assets/img/logo-qrcode-mobilize.png";

function QrCode({ stationID }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
  }, [width]);

  // function getQrCodeLogo() {
  //   if (qrcodeCompany === "neocharge") return logoQrCodeNeocharge;
  //   if (qrcodeCompany === "mobilize") return logoQrCodeMobilize;

  //   return logoQrCodeTupinamba;
  // }

  return (
    <div>
      <QRCode
        size={width / 10}
        level={"H"}
        renderAs="svg"
        style={{
          // padding: 5,
          // backgroundColor: "#221b2a",
          marginLeft: width / 18,
        }}
        // bgColor="#221b2a"
        // fgColor="#FFFFFF"
        value={`${process.env.REACT_APP_STATION_URL}/target/${stationID}`}
        imageSettings={{
          width: width / 25,
          height: width / 32,
          src: logoQrCode,
          excavate: true,
        }}
      />
    </div>
  );
}

export default QrCode;
