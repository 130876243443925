const isAvailable = (plugs) => {
  let haveStatus = false;

  haveStatus = Some(plugs, "Available");
  if (haveStatus) {
    return "Available";
  }
  return false;
};

const Some = (plugs, status) => {
  return plugs.some((plug) => plug.stateName === status);
};

export default isAvailable;
